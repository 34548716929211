import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField as MuiTextField } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    margin: '0 18%',
    padding: 30,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0 10%'
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: '30px 20px'
    }
  },
  title: {
    textAlign: 'center',
    margin: '10px 0 40px 0',
    '& .fa-envelope-open-text': {
      fontSize: 53,
      color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    }
  },
  subTitle: {
    fontWeight: 300,
    fontSize: 15,
    marginBottom: 20
  },
  container: {
    padding: '20px 0'
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    fontFamily: 'inherit',
    color: 'inherit',
    textTransform: 'none',
    fontWeight: 400,
    marginRight: 10,
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 20,
    fontSize: 13,
    '&.number': {
      minWidth: 0,
      borderRadius: '50%',
      height: 60,
      width: 60,
      padding: 5,
      '&.active': {
        border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
        color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
        fontWeight: 'bold'
      },
      [theme.breakpoints.down('md')]: {
        height: 42,
        padding: 3
      },
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]:{
        height: 45,
        padding: 3
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 4,
        height: 33,
        padding: 3
      },
    },
    '&.string': {
      '&.active': {
        backgroundColor: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
        color: 'white'
      }
    }
  },
  submitBtn: {
    fontFamily: 'inherit',
    textTransform: 'none',
    fontWeight: 400,
    marginRight: 10,
    marginTop: 30,
    width: '100%',
    backgroundColor: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    color: 'white',
    borderRadius: 20,
    fontSize: 13,
    '&:hover': {
      color: 'black'
    },
    '&:disabled': {
      backgroundColor: '#e8e8e8',
      border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
    '&.small': {
      marginTop: 20
    }
  },
  socialMediaBtn: {
    display: 'block',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 300,
    borderRadius: 20,
    margin: 5,
    padding: 25,
    '&.facebook': {
      color: '#1770b8'
    },
    '&.instagram': {
      color: '#f44c5d',
      '& .fab': {
        background: 'radial-gradient(circle at 10% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
      }
    },
    '&.youtube': {
      color: '#ff0200',
    },
    '&.linkedIn': {
      color: '#0077b5'
    },
    '& .fab': {
      display: 'block',
      fontSize: 30,
      marginBottom: 15
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      height: 90,
      padding: 0
    }
  },
  socialMediaText: {
    marginTop: 70,
    fontWeight: 300,
    fontSize: 20,
    '&.small': {
      marginTop: 20
    }
  }
}));

export const TextField = withStyles({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      fontFamily: 'inherit',
      fontWeight: 400,
      fontSize: 14,
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000003b',
      },
    },
  },
})(MuiTextField);
