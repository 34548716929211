import React from 'react';
import { Backdrop, Grow, Grid } from '@material-ui/core';
import {useConfigContext} from '../../../_context/config.context'
import useStyles from '../styles/qrExtraOptionsStyles'
import formatter from "../../../_helpers/formatter";

export default function QrExtraOptions({ toggleOpen, open, options, windowSize, sendMessage }) {
  const {config} = useConfigContext()
  const classes = useStyles({...windowSize, ...config})

  const selectOption = (qr, e) => {
    sendMessage(qr)
    toggleOpen(e)
  }

  return (
    <Backdrop
      open={open}
      onClick={toggleOpen}
      classes={{ root: classes.backdrop}}
      id='toggle-backdrop'
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div className={classes.backdropContent}>
          <Grid container>
            <Grid item xs={12}>
              <h1 className={classes.header}>Select an option to view</h1>
            </Grid>
          </Grid>
          {
            options.length > 0 && options.map(item =>
              <Grid item xs={12} key={item.payload}>
                <div
                  className={classes.option}
                  onClick={(e) => selectOption(item, e)}
                  id='toggle-backdrop'
                >
                   {formatter(item.text)}
                </div>
              </Grid>
            )
          }
        </div>
      </Grow>
    </Backdrop>
  );
}
