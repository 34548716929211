import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'black',
  },
  content: {
    height: props => `${ props.height - 50}px`,
    textAlign: 'center',
    overflowX: 'scroll',
    [theme.breakpoints.down('md')]: {
      height: props => `${ props.height - 63}px !important`
    }
  },
}));
