import React from 'react';
import {Helmet} from "react-helmet";
import { ReactComponent as MaintenenceIcon } from '../../assets/icons/Maintenance.svg';
import useStyles from './styles'

export default function MaintenancePage({ config, missingKeys}) {
  const { title, message } = config.maintenance
  const classes = useStyles()
  return (
    <div style={{ fontFamily: config.FONT_FAMILY}} className={classes.maintenanceContainer}>
      <Helmet>
        <meta
          name="description"
          content={config.BOT_NAME}
        />
        <title>Under Maintenance</title>
      </Helmet>
      <MaintenenceIcon className='maintenance-icon'/>
      <div>
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
    </div>
  )
}
