import React, { useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles/defaultStyles'

export default function SchrodersFeedbackPage({ windowSize, endFeedback, handleEndChatBtn}) {
  const classes = useStyles({...windowSize})

  useEffect(() => {
    setTimeout(()=>{
      endFeedback()
      handleEndChatBtn()
    }, 2000)
  })

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.header}>
        Thank you
      </div>
    </Grid>
  );
}
