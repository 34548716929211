import React from 'react';
import moment from 'moment'
import FileIconGenerator from '../../../_helpers/fileIconGenerator'
import useStyles from '../styles/filePlaceholderMessageStyles'

export default function FilePlaceholderMessage({ nextMsg, isLast, toggleOpen, config, ...props }) {
  const classes = useStyles(config);
  const { data, createdAt} = props //add createdBy, here to renable user form response
  const { items } = data
  
  return (
      <div>
        <div className={classes.messageContainer} data-testid='FilePlaceholder-message'>
          <div className={classes.internalText}>
            {items[0].fileName}
            <img src={FileIconGenerator(items[0].fileExtension)} alt='fileIcon' />
          </div>
          <span className={classes.userTimeStamp}>{moment(createdAt).format("h:mm a")}</span>
        </div>
        <div className={classes.clearDiv} />
      </div>
  );
}
