export const webShare = (shareData) => {
  if(navigator.share) {
    navigator.share({
      title: shareData?.title,
      text: shareData?.text,
      url: shareData?.url,
    })
      .catch((error) => {
        if (error.code === DOMException.ABORT_ERR) {
          console.log('Aborted');
        } else {
          alert('Error while sharing');
        }
      });
  } else {
    alert('Web share not supported on your browser')
  }
}