import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    borderRadius: 15,
    minHeight: 0,
    width: 'fit-content',
    textAlign: 'left',
    padding: '22px 25px 18px 25px',
    boxShadow: '1px 3px 7px 0px #d4d4d4',
    margin: '12px auto 12px auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .shareButton': {
      position: 'absolute',
      borderRadius: '50%',
      minWidth: 0,
      width: 30,
      height: 30,
      backgroundColor: ({SHARE_BUTTON_BACKGROUND_COLOR}) => SHARE_BUTTON_BACKGROUND_COLOR,
      color: ({SHARE_ICON_COLOR}) => SHARE_ICON_COLOR,
      top: '50%',
      right: -40,
      "-ms-transform": 'translateY(-50%)',
      transform: 'translateY(-50%)',
      '&:active': {
        transform: 'translateY(-48%)',
      }
    },
    [theme.breakpoints.down('xs')]: {
      margin: ({ showShareBtn }) => showShareBtn ? '12px 20px' : '12px auto',
      width: ({ showShareBtn }) => showShareBtn ? '75%' : 'fit-content'
    }
  },
  buttonHeader: {
    fontSize: 13,
    overflowWrap: 'break-word',
    marginBottom: 20,
    whiteSpace: 'pre-line'
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  options: {
    textTransform: 'none',
    fontFamily: 'inherit',
    lineHeight: 1.3,
    padding: '5px 20px',
    margin: '5px 0px',
    transition: '0.1s ease-in',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    backgroundColor: 'transparent',
    borderRadius: 20,
    fontSize: 13,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    // width: 'calc(100% - 40px)',
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
      color: ({BUTTON}) => BUTTON.hoverState.color,
    },
    '&:active': {
      transform: 'translateY(1px)'
    },
    '&:disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)'
    }
  },
  list: {
    padding: '0 15px',
    margin: '10px 0 0 0'
  }
}));
