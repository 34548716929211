import { makeStyles } from '@material-ui/core/styles';

const notificationZIndex = 500 //to ensure that it is hidden beneath forms

export default makeStyles(theme => ({
  root: {
    color: ({NOTIFICATION_BANNER: { color }}) => color,
    position: 'absolute',
    top: ({WIDE_MODE}) => WIDE_MODE ? 20 : 68,
    zIndex: notificationZIndex,
    marginRight: ({WIDE_MODE}) => WIDE_MODE ? 25 : 12,
    width: 'calc(100% - 95px)',
    maxWidth: '94%',
    borderRadius: 10,
    padding: '12px 30px 12px 20px',
    backgroundColor:({NOTIFICATION_BANNER: { backgroundColor }}) => backgroundColor,
    boxShadow: '0px 1px 3px 1px #00000030',
    marginLeft: ({WIDE_MODE}) => WIDE_MODE ? 0 : -10,
    '& h4': {
      margin: '7px 0 0 0'
    },
    '& p': {
      margin: '10px 0',
      fontSize: 14,
      fontWeight: 500
    },
    '& .closeIcon': {
      position: 'absolute',
      top: 7,
      right: 7,
      width: 20,
      padding: 5,
      borderRadius: 40,
      transition: '0.2s ease-in-out',
      fill: 'white',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#c1baba80'
      }
    },
    '&.hidden': {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 66px)',
      maxWidth: '96% !important',
      top: '68px !important',
      marginLeft: '-16px !important',
      marginRight: '10px !important',
    }
  },
  button: {
    textTransform: 'none',
    fontFamily: 'inherit',
    backgroundColor: ({NOTIFICATION_BANNER: { button: { backgroundColor } }}) => backgroundColor,
    color: ({NOTIFICATION_BANNER: { button: { color } }}) => color,
    borderRadius: 20,
    padding: '2px 16px',
    fontWeight: 'bold',
    fontSize: 12,
    '&:hover': {
      backgroundColor: 'black'
    }
  }
}));
