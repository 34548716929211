import React, {useState} from 'react';
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid';
import TextTransition, { presets } from "react-text-transition";
import useStyles from './styles/defaultStyles'

export default function DefaultFeedbackPage({ windowSize, endFeedback, handleEndChatBtn}) {
  const classes = useStyles({...windowSize})
  const [responseText, setResponseText] = useState(null)
  const [showOptions, setShowOptions] = useState(true)

  const submitFeedback = status => {
    setTimeout(()=>{
      setShowOptions(false)
    }, 400)
    setResponseText(status === 'good' ? 'Thank you for your feedback' : 'Your opinions have been forwarded to our Customer Service Rep')
    setTimeout(()=>{
      endFeedback()
      handleEndChatBtn()
    }, 2000)
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.header}>
        <TextTransition
          text={responseText ? responseText : 'How was your conversation?'}
          springConfig={presets.wobbly}
          style={{
            textAlign: 'center'
          }}
        />
      </div>
      {
        showOptions
        &&
        <div className={clsx(classes.thumbsContainer, responseText && 'exit')}>
          <div className={classes.thumbDiv} onClick={() => submitFeedback('good')}>
            <i className="far fa-thumbs-up" />
            <div className='label thumbs-up-label'>Good</div>
          </div>
          <div className={classes.thumbDiv} onClick={() => submitFeedback('bad')}>
            <i className="far fa-thumbs-down" />
            <div className='label thumbs-down-label'>Bad</div>
          </div>
        </div>
      }
      <p className={classes.description}>
        this is the final screen after the session ends
      </p>
    </Grid>
  );
}
