import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import Launcher from '../components/Launcher'
import ConfigErrorPage from './pages/configurationError'
import MaintenancePage from './pages/maintenance'
import UserContext from '../_context/user.context'
import ConfigContext from '../_context/config.context'
import settings from '../config/settings'
import defaultSettings from '../config.sample/settings'
import { connect } from 'react-redux'

import './App.css';

function App({user, dispatch}) {

  const requiredKeys = Object.keys(defaultSettings)
  const localSettings = Object.keys(settings)
  const missingKeys = requiredKeys.filter(x => !localSettings.includes(x))

  const [config, setConfig] = useState(settings)

  const modifyConfig = newConfig => {
    const configClone = JSON.parse(JSON.stringify(config))
    const updatedConfig = {...configClone, ...newConfig}
    setConfig(newConfig ? updatedConfig : settings)
  }

  if(missingKeys.length > 0) {
    return <ConfigErrorPage config={config} missingKeys={missingKeys} />
  } else if(settings.maintenance.active) {
    return <MaintenancePage config={config} />
  } else {
    return (
      <UserContext.Provider value={{user, dispatch}}>
        <ConfigContext.Provider value={{config, modifyConfig}}>
          {
            config.WIDE_MODE && <Helmet>
              <meta
                name="description"
                content={config.BOT_NAME}
              />
              <title>{config.BOT_NAME}</title>
            </Helmet>
          }
          <div className="App" style={{ fontFamily: config.FONT_FAMILY}}>
            <Launcher />
          </div>
        </ConfigContext.Provider>
      </UserContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(App)
