import { createContext, useContext } from 'react'
// import styles from '../config/styles'
import settings from '../config/settings'

const compiledConfig = { ...settings}

let defaultConfig = {config: compiledConfig, modifyConfig: () => {}}

const ConfigContext = createContext(defaultConfig);

export const useConfigContext = () => useContext(ConfigContext)

export default ConfigContext
