import React, {useState} from 'react';
import {isMobile} from "react-device-detect";
import {Button} from '@material-ui/core'
import useStyles from '../styles/imageMessageStyles'

export default function ImageMessage({windowSize, data, sendMessage, config, webSocket }) {
  const {
    url = null,
    thumbnailDesktop = null,
    thumbnailMobile = null
  } = data
  const [imgSize, setImgSize] = useState({naturalWidth: 0, naturalHeight: 0})
  const classes = useStyles({ url, imgSize, windowSize, ...config });
  const {IMG_TEMPLATE_SHOW_FULL} = config

  const handleButtonClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root} data-testid='img-message'>
      {
        IMG_TEMPLATE_SHOW_FULL ?
        <img
          src={isMobile ? thumbnailMobile : thumbnailDesktop}
          alt='data'
          onClick={handleButtonClick}
          onLoad={(e) => setImgSize(e.target)}
        />
        :
        <>
          <div
            data-testid='image-container'
            className='-image'
          />
          <div className={classes.optionsContainer}>
            <Button
              className={classes.options}
              onClick={handleButtonClick}
              disabled={!webSocket}
              >
              View Image
            </Button>
          </div>
        </>
      }
    </div>
  );
}
