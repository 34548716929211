const defaultTemplate = {
  textFields: [
    { label: 'Username/Email', key: 'username', value: '' },
    { label: 'Password', key: 'password', value: '', action: 'submit' },
  ],
  buttons: [
    { label: 'Login', action: 'submit', dataTestId: 'login-form-login-btn' },
    { label: 'Cancel', action: 'cancel', dataTestId:'login-form-cancel-btn' },
  ]
}

const emailAuth = {
  icon: "far fa-envelope",
  textFields: [
    { label: 'Email', key: 'email', value: '', action: 'submit', helperText: 'Once signed in, you do not have to register on this browser again' },
  ],
  buttons: [
    { label: 'Submit', action: 'submit' },
  ]
}

const otp = {
  icon: "fas fa-key",
  textFields: [
    { label: 'OTP', key: 'otp', value: '', action: 'submit'  },
  ],
  buttons: [
    { label: 'Submit', action: 'submit' },
    { label: 'Cancel', action: 'cancel' },
  ]
}

export default (formTitle) => {
  switch(formTitle){
    case 'USERNAME_PASSWORD_FORM':
    return defaultTemplate
    case 'EMAIL_AUTH_FORM':
    return emailAuth
    case 'OTP_FORM':
    return otp
    default:
    return defaultTemplate
  }
}
