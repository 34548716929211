import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  messageContainer: {
    padding: '16px 18px',
    fontSize: 14,
    textAlign: 'left',
    color: ({USER_MESSAGE_COLOR}) => USER_MESSAGE_COLOR,
    borderRadius: 10,
    margin: '10px 0px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    lineHeight: '1.2',
    maxWidth: 280,
    display: 'block',
    position: 'relative',
    '&.postbackMessage': {
      marginLeft: 40,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 25,
      float: 'right',
      color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
      border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    }
  },
  userTimeStamp: {
    fontSize: 9,
    position: 'relative',
    paddingRight: 10,
    display: 'block',
    '& .read-icon': {
      position: 'absolute',
      bottom: 0,
      width: 11,
      height: 11,
      fill: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    }
  },
  internalText: {
    paddingBottom: 5
  },
  clearDiv: {
    clear: 'both'
  }
}));
