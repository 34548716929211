import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    textAlign: 'center'
  },
  lineWithText: {
    border: 'none',
    borderTop: '1px solid #bfbfbf',
    color: '#888888',
    overflow: 'visible',
    textAlign: 'center',
    height: 5,
    '&:after': {
      background: '#fff',
      content: ({text}) => `"${text}"`,
      padding: '0 20px',
      position: 'relative',
      fontSize: 14,
      top: -10,
      [theme.breakpoints.only('xs')]: {
        padding: '0 5px',
        fontSize: 12
      }
    }
  }
}));
