import React from 'react';

import {useConfigContext} from '../../_context/config.context'
import TextMessage from './components/TextMessage'
import ButtonMessage from './components/ButtonMessage'
import GenericMessage from './components/GenericMessage'
import VideoMessage from './components/VideoMessage'
import ImageMessage from './components/ImageMessage'
import PostbackMessage from './components/PostbackMessage'
import ReferralMessage from './components/ReferralMessage'
import NotificationMessage from './components/NotificationMessage'
import FilePlaceholderMessage from './components/FilePlaceholderMessage'

export default function Message(props) {
  const {config} = useConfigContext()

  const generateComponent = () => {
    switch(props.type){
      case 'message':
      return <TextMessage {...props} config={config} />
      case 'button_template':
      return <ButtonMessage {...props} config={config} />
      case 'video':
      return <VideoMessage {...props} config={config} />
      case 'image':
      return <ImageMessage {...props} config={config} />
      case 'generic_template':
      return <GenericMessage {...props} config={config} />
      case 'postback':
      return <PostbackMessage {...props} config={config} />
      case 'form':
      return <PostbackMessage {...props} config={config} />
      case 'referral':
      case 'livechat':
      return <ReferralMessage {...props} config={config} />
      case 'notification':
      return <NotificationMessage {...props} config={config} />
      case 'images':
      case 'files':
      case 'videos':
      case 'audios':
      return <FilePlaceholderMessage {...props} config={config} />
      default:
      return <TextMessage {...props} config={config} />
    }
  }
  return generateComponent()
}
