import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles/postSessionScreenStyles'
import Header from '../elements/header'
import DefaultPage from './feedbackTemplates/default'
import CIMBPage from './feedbackTemplates/cimb'
import SchrodersPage from './feedbackTemplates/schroders'
import CIMBMYPage from './feedbackTemplates/cimbmy'
import {useConfigContext} from '../../../_context/config.context'

export default function PostSessionScreen({ windowSize, endFeedback, sendForm, webSocket, handleEndChatBtn}) {
  const {config} = useConfigContext()
  const {FEEDBACK_FORM_NAME} = config
  const classes = useStyles({...windowSize})

  const loadPage = () => {
    switch(FEEDBACK_FORM_NAME){
      case 'schroders':
      return <SchrodersPage endFeedback={endFeedback} handleEndChatBtn={handleEndChatBtn} />
      case 'cimb':
      return <CIMBPage
        endFeedback={endFeedback}
        config={config}
        sendForm={sendForm}
        webSocket={webSocket}
      />
      case 'cimbmy':
      return <CIMBMYPage
        endFeedback={endFeedback}
        config={config}
        sendForm={sendForm}
        webSocket={webSocket}
      />
      default:
      return <DefaultPage endFeedback={endFeedback} handleEndChatBtn={handleEndChatBtn}/>
    }
  }

  return (
    <Grid item container className={classes.root}>
      <Header />
      <Grid item xs={12} className={classes.content} container>
        {loadPage()}
      </Grid>
    </Grid>
  );
}
