import React from 'react';
import clsx from 'clsx'
import moment from 'moment'
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg'
import useStyles from '../styles/postbackMessageStyles'
import formatter from "../../../_helpers/formatter";

export default function TextMessage({ nextMsg, isLast, toggleOpen, config, ...props }) {
  const classes = useStyles(config);
  const {SHOW_USER_FORM_RESPONSE} = config
  const { data, createdBy, createdAt, type = 'non-postback', readState } = props //add createdBy, here to renable user form response
  const { text, title } = data
  
  return (
      type === 'postback' || // normal postback
      (type === 'form' && createdBy === 'user' && SHOW_USER_FORM_RESPONSE) //form postback enable this to show form response from user end
      ?
      <div>
        <div className={clsx(classes.messageContainer, 'postbackMessage')} data-testid='postback-message'>
          <div className={classes.internalText}>
            {formatter(text ? text : title)}
          </div>
          <span className={classes.userTimeStamp}>{moment(createdAt).format("h:mm a")} {readState && <TickIcon className='read-icon' />}</span>
        </div>
        <div className={classes.clearDiv} />
      </div>
      :
      null
  );
}
