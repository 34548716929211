import { makeStyles } from '@material-ui/core/styles';
import LaunchIconImg from '../../assets/images/launch_icon.png'

export default makeStyles(theme => ({
  launcherButton: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
    backgroundColor: ({GLOBAL_COLOR, USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER_ICON ? 'transparent' : GLOBAL_COLOR,
    background: ({USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER_ICON ? `url(${LaunchIconImg}) no-repeat center center` : 'auto',
    backgroundSize: ({USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER_ICON ? 'cover !important': 'auto',
    height: ({launchButtonSize}) => launchButtonSize,
    width: ({launchButtonSize}) => launchButtonSize,
    borderBottomRightRadius: 0,
    transition: '0.2s ease-in',
    '&.chat-open': {
      borderBottomRightRadius: '50%',
      borderTopRightRadius: 0,
      height: ({launchButtonSize}) => launchButtonSize * 0.8,
      width: ({launchButtonSize}) => launchButtonSize * 0.8,
    },
    '&:hover': {
      backgroundColor: ({USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER_ICON ? 'none' : '#060606'
    },
    [theme.breakpoints.down('sm')]: {
      zIndex:({chatIsOpen}) => chatIsOpen ? -1 : 0
    },
    [theme.breakpoints.up('sm')]: {
      display: ({ WIDE_MODE }) => WIDE_MODE ? 'none' : 'auto'
    }
  },
  messageIcon: {
    color: 'white',
    fontSize: 25,
    '&.fa-comments': {
      animation: '$fade-in 0.4s ease-in-out',
      '&.open': {
        animation: '$spin-in 0.3s ease-in-out',
      }
    },
    '&.fa-times': {
      animation: '$spin-out 0.3s ease-in-out',
      '&.open': {
        fontSize: 25 * 0.8,
        animation: '$spin-in 0.3s ease-in-out',
      }
    }
  },
  badge: {
    color: '#fff',
    fontFamily: 'inherit',
    backgroundColor: 'red',
    transition: '0.2s ease-in-out',
  },
  '@keyframes spin-out': {
    '0%': {
      transform: 'rotate(150deg)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(rotate(270deg))',
      opacity: 0
    },
  },
  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    },
  },
  '@keyframes spin-in': {
    '0%': {
      transform: 'rotate(-150deg)',
      opacity: 0
    },
    '100%': {
      transform: 'scale(rotate(10deg))',
      opacity: 1
    },
  },
}));
