import React from 'react';
import {
  Button
} from '@material-ui/core';
import clsx from 'clsx'
import {useConfigContext} from '../../../_context/config.context'

import useStyles from '../styles'

export default function Tou({ formAction, form }) {
  const {config} = useConfigContext()
  const classes = useStyles(config)

  return (
    <div>
      <div
        className={classes.touContainer}
        dangerouslySetInnerHTML={{ __html: form ? form.data.text : ''}}
      />
      <Button
        variant='contained'
        onClick={() => formAction({ action: 'submit'})}
        className={clsx(classes.actionButton, 'secondary', 'touBtn')}
      >
        Accept
      </Button>
    </div>
  );
}
