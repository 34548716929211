import React from 'react'
import formatter from "./formatter";

export default function ListFilter(data, Parent, classes){
  let firstListIndex = null
  let lastListIndex = null
  let newLineSplit = data.split('\n')
  let listItems = []

  newLineSplit.forEach((phrase, i) => {
    if(phrase.substring(0,2) === '- ' && !firstListIndex) {
      firstListIndex = i
    } else if(phrase.substring(0,2) === '- ' && newLineSplit[i + 1]?.substring(0,2) !== '- '){
      lastListIndex = i
    } else if(phrase.substring(0,2) === '- ' && !newLineSplit[i + 1]) {
      lastListIndex = i
    }
  })
  listItems =  newLineSplit.slice(firstListIndex, lastListIndex + 1)
  
  if(firstListIndex) return (
    <Parent>
      {
        firstListIndex !== 0 && formatter(newLineSplit.slice(0, firstListIndex).join('\n'))
      }
      <ul className={classes.list}>
        {listItems.map((item, idx) => <li key={idx}>{formatter(item.replace('- ', ''))}</li>)}
      </ul>
      {
        lastListIndex !== newLineSplit.length - 1 && formatter(newLineSplit.slice(lastListIndex + 1, newLineSplit.length).join('\n'))
      }
    </Parent>
  )
  return <Parent>{data}</Parent>
}
