import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '10px 0px',
    '&.userMessageContainer + &.userMessageContainer': {
      margin: '1px 0'
    },
  },
  messageContainer: {
    padding: '16px 18px',
    fontSize: 14,
    textAlign: 'left',
    color: ({USER_MESSAGE_COLOR}) => USER_MESSAGE_COLOR,
    borderRadius: 10,
    margin: '10px 0px',
    whiteSpace: 'pre-wrap',
    maxWidth: ({WIDE_MODE}) => WIDE_MODE ? '80%' : 320,
    overflowWrap: 'break-word',
    lineHeight: '1.2',
    display: 'block',
    position: 'relative',
    '&.botMessage': {
      color: ({BOT_MSG}) => BOT_MSG.color ?? 'black',
      backgroundColor: ({BOT_MSG}) => BOT_MSG.backgroundColor ?? '#e5edf1',
      marginRight: 40,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 25,
      float: 'left',
      '& .shareButton': {
        position: 'absolute',
        borderRadius: '50%',
        minWidth: 0,
        width: 30,
        height: 30,
        backgroundColor: ({SHARE_BUTTON_BACKGROUND_COLOR}) => SHARE_BUTTON_BACKGROUND_COLOR,
        color: ({SHARE_ICON_COLOR}) => SHARE_ICON_COLOR,
        top: '50%',
        right: -40,
        "-ms-transform": 'translateY(-50%)',
        transform: 'translateY(-50%)',
        '&:active': {
          transform: 'translateY(-48%)',
        }
      }
    },
    '&.userMessage': {
      marginLeft: 40,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 25,
      float: 'right',
      backgroundColor: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    },
    // '&.userMessage + &.userMessage': {
    //   margin: '3px 0px 3px 40px',
    //   borderTopRightRadius: 0,
    //   borderBottomRightRadius: 0
    // },
    // '&.botMessage + &.userMessage': {
    //   marginLeft: 40,
    //   marginBottom: 0,
    //   borderBottomRightRadius: 0,
    //   borderTopRightRadius: 25,
    // },
    // '&.userMessage + &.botMessage': {
    //   marginBottom: 0,
    //   borderTopLeftRadius: 25,
    //   borderBottomLeftRadius: 0
    // },
    // '&.botMessage + &.botMessage': {
    //   margin: '3px 40px 3px 0px',
    //   borderTopLeftRadius: 0,
    //   borderBottomLeftRadius: 0
    // },
    '&.lastBotMessage': {
      marginRight: 40,
      borderTopLeftRadius: '25px !important'
    },
    '&.lastUserMessage': {
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '25px !important'
    },
    '&.lastUserMessageInChat': {
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '25px !important'
    }
  },
  userTimeStamp: {
    fontSize: 9,
    position: 'relative',
    paddingRight: 10,
    display: 'block',
    '& .read-icon': {
      position: 'absolute',
      bottom: 0,
      width: 11,
      height: 11,
      fill: ({USER_MESSAGE_COLOR}) => USER_MESSAGE_COLOR,
    }
  },
  botTimeStamp: {
    fontSize: 9,
    float: 'right',
    color: ({BOT_MSG}) => BOT_MSG.timeStampColor
  },
  internalText: {
    paddingBottom: 5
  },
  ellipsisText: {
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'right',
    display: 'block',
    marginTop: 10,
    fontWeight: 700
  },
  clearDiv: {
    clear: 'both'
  },
  list: {
    padding: '0 15px',
    margin: '10px 0 0 0'
  }
}));
