import React from 'react';
import useStyles from '../styles/videoMessageStyles'

export default function VideoMessage({ data, config }) {
  const classes = useStyles(config);
  const { url, poster, autoplay, controls = true, loop } = data

  return (
    <div className={classes.root}>
      <video 
        className={classes.videoPlayer} 
        playsInline 
        data-testid='video-message'
        autoPlay={autoplay}
        poster={poster}
        controls={controls}
        loop={loop}
      >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/ogg" />
        <source src={url} type="video/avi" />
        Your browser doesn't support HTML5 video tag.
      </video>
    </div>
  );
}