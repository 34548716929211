import axios from "axios";
import humps from 'humps'
// import { getUser } from "../_helpers/localStorage"

// const userToken = getUser() ? getUser().token : null

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: false,
  transformResponse: [
   ...axios.defaults.transformResponse,
   data => humps.camelizeKeys(data)
 ],
 transformRequest: [
   data => humps.decamelizeKeys(data),
   ...axios.defaults.transformRequest
 ]
});

//Use this instead of the default export when trying to get data outside of a functional component. React will throw an error otherwise
export default function InstanceWrapper(type, url, data, axiosSource){
  switch(type) {
    case 'GET':
    return instance.get(url, { cancelToken: axiosSource.token })
    case 'POST':
    return instance.post(url, data, { cancelToken: axiosSource.token })
    case 'LOGIN_POST':
    return instance.post(url, data)
    case 'PUT':
    return instance.put(url, data, { cancelToken: axiosSource.token })
    case 'DELETE':
    return instance.delete(url, data, { cancelToken: axiosSource.token })
    default:
    throw new Error('UNABLE TO LOAD AXIOS INSTANCE, CHECK BODY')
  }
}
