import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Badge as MaterialBadge } from '@material-ui/core';

export const Badge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$throb 1.2s infinite ease-in-out',
      border: '1px solid #44b700',
      content: '""',
    },
  },
  '@keyframes throb': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(MaterialBadge);

export default makeStyles(theme => ({
  header: {
    backgroundColor: ({HEADER_COLOR}) => HEADER_COLOR,
    padding: '10px 25px',
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      display: ({WIDE_MODE}) => WIDE_MODE ? 'none' : 'auto'
    }
  },
  profileImage: {
    flex: 1,
    height: 30,
    maxWidth: 30
  },
  headerText: {
    marginLeft: 15,
    fontSize: 15,
    flex: 4,
    lineHeight: 1.5
  },
  ellipsisIcon: {
    padding: 8,
    cursor: 'pointer',
    fill: 'white'
  },
  menuList: {
    borderTopRightRadius: 0,
    padding: 0,
    borderRadius: 8
  },
  menuItem: {
    color: 'black',
    fontSize: 14,
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: '#b6d6f73d',
      fontWeight: 'bold',
      color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    }
  },
  menuItemIcon: {
    fontSize: 'inherit',
    marginRight: 10
  }
}));
