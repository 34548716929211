import React, {useState, useEffect} from 'react';
import {
  Grid,
  TextField
} from '@material-ui/core';
import clsx from 'clsx'
import {useConfigContext} from '../../../_context/config.context'
import useStyles from '../styles'

export default function LoginForm({ formAction, form, data }) {
  const {config} = useConfigContext()
  const classes = useStyles(config)
  const [fields, setFields] = useState({})
  const [loading, setLoading] = useState(false)

  const handleTextField = e => {
    const clone = {...fields}
    clone.textFields[clone.textFields.findIndex(item => item.key === e.target.name)].value = e.target.value
    setFields(clone)
  }

  const handleKeyDown = (e, action) => {
    if(e.key === 'Enter' && action) {
      setLoading(true)
      formAction({ action, fields})
    }
  }

  const handleButtonClick = action => {
    setLoading(true)
    formAction({action, fields})
    setFields(JSON.parse(JSON.stringify(data)))
  }

  useEffect(() => {
    setFields(JSON.parse(JSON.stringify(data)))
  }, [data])

  useEffect(() => setLoading(false), [form.id])

  const { textFields = [], buttons = [], icon } = fields
  return (
    <Grid container className={classes.contentGrid} data-testid='login-form'>
      <Grid item xs={12}>
        <i className={clsx(icon ? icon : "far fa-user-circle", classes.headerIcon)} />
      </Grid>
      <Grid item xs={12}>
        {
          form?.data?.text.split('\n').map((item, key) =>
            (item.includes('emphasized-text') || item.includes('light-text')) ?
            <h5 key={key} className={classes.formHeader} dangerouslySetInnerHTML={{ __html: item }} />
            :
            <h5 key={key} className={classes.formHeader} data-testid='login-form-header'>{item}</h5>
          )
        }
      </Grid>
      {
        textFields.map((f, i) =>
          <Grid item xs={12} key={i}>
            <TextField
              autoFocus
              variant="standard"
              className={classes.textField}
              label={f.label}
              name={f.key}
              value={f.value}
              helperText={f.helperText}
              onChange={handleTextField}
              disabled={loading}
              onKeyDown={e => handleKeyDown(e, f.action)}
            />
          </Grid>
        )
      }
      <div className={classes.divider} />
      {
        buttons.map((btn, i) =>
          <Grid item xs={12} key={i}>
            <button
              className={classes.actionButton}
              data-testid={btn.dataTestId}
              onClick={() => handleButtonClick(btn.action)}
            >
              {btn.label}
            </button>
          </Grid>
        )
      }
    </Grid>
  );
}
