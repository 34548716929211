import React, {useState} from 'react';
import clsx from 'clsx'
import uuid from 'uuid'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Rating from '@material-ui/lab/Rating';
import {Grid, Button, Fade} from '@material-ui/core';
import useStyles, {TextField} from './styles/cimbStyles'

export default function CIMBFeedbackPage({ windowSize, endFeedback, config, sendForm, webSocket}) {
  const classes = useStyles({...windowSize, ...config})
  const [responses, setResponses] = useState([
    {
      question: 'How was your experience for the session today?',
      type: 'rating',
      value: ''
    }
  ])

  // [
  //   {
  //     question: 'How would you rate your session today?',
  //     answers: [1,2,3,4,5,6,7,8,9,10],
  //     type: 'number',
  //     value: ''
  //   },
  //   {
  //     question: 'How was the speed of my reply today?',
  //     answers: ['Fast', 'Moderate', 'Slow'],
  //     type: 'string',
  //     value: ''
  //   },
  //   {
  //     question: 'Do you have any other comments/feedback for us?',
  //     type: 'textArea',
  //     value: ''
  //   }
  // ]
  const [responseSubmitted, setResponseSubmitted] = useState(false)

  const handleRating = (e, value) => {
    const responseClone = JSON.parse(JSON.stringify(responses))
    responseClone[responseClone.findIndex(clone => clone.question === e.target.name)].value = value
    setResponses(responseClone)
  }
  const handleChange = (option, answer) => {
    const responseClone = JSON.parse(JSON.stringify(responses))
    responseClone[responseClone.findIndex(clone => clone.question === option.question)].value = answer
    setResponses(responseClone)
  }

  const handleTextField = e => {
    const responseClone = JSON.parse(JSON.stringify(responses))
    responseClone[responseClone.findIndex(clone => clone.question === e.target.name)].value = e.target.value
    setResponses(responseClone)
  }

  const submitFeedback = () => {
    let filteredFields =
      responses.map(response => {
        return {
          key: response.question,
          value: response.value
        }
      })
    const formPayload = {
        type: 'form',
        id: uuid(),
        createdAt: new Date(),
        createdBy: 'user',
        data: {
            action: 'submit',
            text: `Submit Feedback`,
            formName: 'CIMB_FEEDBACK_FORM',
            formFields: filteredFields
        }
    }
    sendForm(formPayload)
    endFeedback()
    setResponseSubmitted(true)
  }

  const refreshPage = () => {
    window && window.location.reload()
  }

  const loadSocialMedia = small => (
    <div className={clsx(classes.socialMediaContainer, 'small')}>
      <Button className={clsx(classes.socialMediaBtn, 'facebook')} onClick={() => window.open("https://www.facebook.com/CIMBMalaysia/", "_blank")}>
        <i className="fab fa-facebook" />
        @CIMB Malaysia
      </Button>
      <Button className={clsx(classes.socialMediaBtn, 'instagram')} onClick={() => window.open("https://www.instagram.com/cimbmalaysia/", "_blank")}>
        <i className="fab fa-instagram" />
        @cimbmalaysia
      </Button>
      <Button className={clsx(classes.socialMediaBtn, 'linkedIn')} onClick={() => window.open("https://www.linkedin.com/company/cimbmalaysia/", "_blank")}>
        <i className="fab fa-linkedin-in" />
        cimb-malaysia
      </Button>
    </div>
  )

  const submitBtnDisabled = !responses.slice(0,1).every(item => item.value !== '') || !webSocket

  if(!responseSubmitted)
    return (
      <Grid item container className={classes.root}>
        <Grid item xs={12}>
          <div className={classes.title}>
            <i className="fas fa-envelope-open-text" />
          </div>
        </Grid>
        {
          responses.map((option, index) =>
            <Grid item xs={12} className={classes.container} key={index}>
              <h3 className={classes.subTitle}>{option.question}</h3>
              <div className={classes.optionsContainer}>
                {
                  option.answers?.map((item, i) =>
                    <Button
                      key={i}
                      variant='outlined'
                      className={clsx(classes.button, option.type, option.value === item && 'active')}
                      onClick={() => handleChange(option, item)}
                      >
                      {item}
                    </Button>
                  )
                }
                {
                  option.type === 'textArea'
                  &&
                  <TextField
                    name={option.question}
                    onChange={handleTextField}
                    variant='outlined'
                    multiline
                    rows={5}
                  />
                }
                {
                  option.type === 'rating'
                  &&
                  <Rating
                    name={option.question}
                    onChange={handleRating}
                    defaultValue={0}
                    precision={1}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  />
                }
              </div>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Button
            variant='outlined'
            className={classes.submitBtn}
            onClick={submitFeedback}
            disabled={submitBtnDisabled}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p className={clsx(classes.socialMediaText, 'small')}>
            Follow us at any of the social media platforms below:
          </p>
          {loadSocialMedia(true)}
        </Grid>
      </Grid>
    )
    else
    return (
      <Fade in={responseSubmitted} timeout={700}>
        <Grid item container className={classes.root}>
          <Grid item xs={12}>
            <p className={classes.socialMediaText}>
              Thank you for your feedback! <br /><br />
              Feel free to come back and chat if you have any other questions. <br />
              Follow us on social media for updates!
            </p>
            {loadSocialMedia()}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='outlined'
              className={classes.submitBtn}
              onClick={refreshPage}
              disabled={submitBtnDisabled}
            >
              Start Another Chat
            </Button>
          </Grid>
        </Grid>
      </Fade>
    )
}
