import { createContext, useContext } from 'react'

let defaultUserValues = {
  email: null
}
const UserContext = createContext(defaultUserValues);

export const useUserContext = () => useContext(UserContext)

export default UserContext
