import { getUser, emptyUserObject } from '../_helpers/localStorage'

const initialState = getUser()

export default function reducer (state = initialState, {type, payload}) {
  switch (type) {
    case 'SESSION_CONNECT_SUCCESS':
    return {
      ...state,
      error: false,
      errorMsg: '',
      sessionId: payload.sessionId
    }
    case 'SESSION_END':
    return {
      ...emptyUserObject
    }
    case 'SESSION_CONNECT_FAILURE':
    return {
      ...state,
      error: true,
      errorMsg: payload.errorMsg,
      sessionId: null
    }
    case 'LOGOUT_SUCCESS':
    return {...initialState}

    default :
    return {...state}
  }
}
