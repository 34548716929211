import React, { useState } from 'react';
import clsx from 'clsx'
import {
  Grid,
  Avatar,
  Menu,
  MenuItem
} from '@material-ui/core';
import { ReactComponent as DotMenu } from '../../../assets/icons/dots-horizontal.svg';
import {useConfigContext} from '../../../_context/config.context'
import botImg from '../../../assets/images/header_icon.png'
import useStyles, { Badge } from '../styles/headerStyles'

/**
 * Use `Header` to provide a source of info for the bot.
 */

export default function Header({ handleEndChatBtn, sendMessage }) {
  const {config} = useConfigContext()
  const classes = useStyles(config);
  const [anchorEl, setAnchorEl] = useState(null);
  const { HEADER_MENU, HEADER_MENU_ITEMS, ENABLE_FEEDBACK } = config

  const triggerPostBack = item => {
    sendMessage({
      title: item.label,
      payload: item.payload,
      text: item.label
    })
  }

  const handleMenu = e => {
    anchorEl === null ? setAnchorEl(e.currentTarget) : setAnchorEl(null)
  }

  const endChat = e => {
    handleMenu(e)
    setTimeout(()=>{
      handleEndChatBtn(ENABLE_FEEDBACK)
    }, 100)
  }

  return (
    <Grid item xs={12} className={classes.header} data-testid='header-container'>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar alt="Stock avatar" src={botImg} />
      </Badge>
      <h1 className={classes.headerText} data-testid='header-bot-name'>
        {config.BOT_NAME}
      </h1>
      {
        HEADER_MENU
        &&
        <DotMenu className={classes.ellipsisIcon} onClick={handleMenu} data-testid='header-menu-btn'/>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenu}
        classes={{
          list: classes.menuList,
          paper: classes.menuList
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div data-testid='header-menu-item-container'>
          {
            HEADER_MENU_ITEMS.map((item, i) =>
              <MenuItem
                dense
                className={classes.menuItem}
                onClick={e => item.payload === 'end' ? endChat(e) : triggerPostBack(item)}
                key={i}
              >
                <i className={clsx(item.icon, classes.menuItemIcon)}></i>
                {item.label}
              </MenuItem>
            )
          }
        </div>
      </Menu>
    </Grid>
  );
}
