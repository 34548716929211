import React, {useState, useEffect} from 'react';
import {Button} from '@material-ui/core'
import listFilter from '../../../_helpers/listFilter'
import useStyles from '../styles/buttonMessageStyles'
import formatter from "../../../_helpers/formatter";
import {webShare} from "../../../_helpers/share";

export default function ButtonMessage({ data, sendMessage, config, webSocket }) {
  const [canShare, setCanShare] = useState(false)
  const { text, buttons, shareData } = data
  const showShareBtn = canShare && shareData
  const classes = useStyles({...config, showShareBtn});

  const triggerShare = () => {
    webShare(shareData);
  }

  const handleButtonClick = btnInfo => {
    if (btnInfo.type === 'postback') {
      btnInfo.text = btnInfo.title
      sendMessage(btnInfo)
    } else if (btnInfo.type === 'url' || btnInfo.type === 'web_url') {
      if(btnInfo.url.includes('tel:'))
        window.location = btnInfo.url
      else
        window.open(btnInfo.url, '_blank');
    }
  };
  useEffect(() => {
    if(navigator.share && !canShare) setCanShare(true)
  }, [setCanShare, canShare])

  // Filter for bullets and convert to list
  const textContainer = ({children}) => <div className={classes.buttonHeader}>{formatter(children)}</div>

  return (
    <div className={classes.root} data-testid='button-message'>
      {listFilter(text, textContainer, classes)}
      <div className={classes.optionsContainer}>
        {buttons.map((btn, i) =>
          <Button
            disabled={!webSocket}
            key={i}
            className={classes.options}
            onClick={() => handleButtonClick(btn)}
          >
          {formatter(btn.title)}
        </Button>
        )}
      </div>
      {showShareBtn
         &&
          <Button className='shareButton' onClick={triggerShare}>
            <i className="fas fa-share" />
          </Button>
      }
    </div>
  );
}
