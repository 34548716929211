import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  errorIcon: {
    width: 300,
    height: 350,
  },
  configErrorContainer: {
    textAlign: 'center',
    maxWidth: 500,
    margin: '0 auto',
    '& h2': {
      fontSize: 29,
      color: 'red'
    },
    '& div': {
      margin: '8px 0',
      fontSize: 14
    }
  },
  maintenanceContainer: {
    textAlign: 'center',
    height: '100vh',
    backgroundColor: '#e4eff3',
    '& h1': {
        paddingTop: 20,
        color: '#263239',
        fontSize: 26,
        fontWeight: 900
    },
    '& .maintenance-icon': {
        width: 600,
        margin: '0 auto',
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
          width: 'calc(100vw - 80px)',
          marginTop: 40
        }
    }
  }
}));
