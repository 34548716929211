import React from 'react';
import {Helmet} from "react-helmet";
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import useStyles from './styles'

export default function ConfigurationErrorPage({ config, missingKeys}) {
  const classes = useStyles()
  return (
    <div style={{ fontFamily: config.FONT_FAMILY}} className={classes.configErrorContainer}>
      <Helmet>
        <meta
          name="description"
          content={config.BOT_NAME}
        />
        <title>Missing Config</title>
      </Helmet>
      <ErrorIcon className={classes.errorIcon} />
      <h2>Configuration keys missing</h2>
      <h5>The following keys are required in your local config file but do not exist, check against the sample file provided for the default values: </h5>
      {missingKeys.map((k, i) =>
        <div key={i}>
          <label>{k}</label> <br />
        </div>
      )}
    </div>
  )
}
