import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    borderRadius: 15,
    minHeight: 0,
    width: 'fit-content',
    textAlign: 'left',
    boxShadow: '1px 3px 7px 0px #d4d4d4',
    margin: '12px auto 12px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  videoPlayer: {
    width: 500,
    height: 'auto',
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));
