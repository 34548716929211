import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  sideBarContainer: {
    position: 'relative',
    backgroundColor: ({BACKGROUND_COLOR}) => BACKGROUND_COLOR,
    height: 'auto',
    textAlign: 'right',
    padding: '40px 4% 40px 4%',
    [theme.breakpoints.down('md')]: {
      padding: 40,
      // height: 'auto'
    }
  },
  navLogo: {
    height: ({SIDEBAR}) => SIDEBAR.logo.height,
    width: ({SIDEBAR}) => SIDEBAR.logo.width,
    '&.href': {
      cursor: 'pointer'
    }
  },
  navItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .item': {
      textAlign: 'center',
      '&.logo': {
        padding: '10px 0 40px 0'
      }
    },
    '& .navLink': {
      textTransform: 'none',
      fontFamily: 'inherit',
      borderRadius: 20,
      width: '90%',
      cursor: 'pointer',
      margin: '8px auto',
      padding: '7px 0',
      fontSize: 14,
      border: ({SIDEBAR}) => `1px solid ${SIDEBAR.borderColor}`,
      backgroundColor: ({SIDEBAR}) => SIDEBAR.backgroundColor,
      color: ({SIDEBAR}) => SIDEBAR.color,
      transition: '0.1s ease-in',
      '&:hover': {
        border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
        backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
        color: ({BUTTON}) => BUTTON.hoverState.color,
      },
      '&:active': {
        transform: 'translateY(4px)'
      },
      '&:disabled': {
        color: '#9e9e9e',
        border: '1px solid #9e9e9e'
      }
    }
  },
  endButton: {
    position: 'absolute',
    left: '50%',
    border: ({SIDEBAR}) => `1px solid ${SIDEBAR.borderColor}`,
    borderRadius: 20,
    width: '60%',
    bottom: 20,
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    fontSize: 14,
    color: ({SIDEBAR}) => SIDEBAR.color,
    textTransform: 'none',
    fontFamily: 'inherit',
    '& .fa-sign-out-alt': {
      marginLeft: 12
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:disabled': {
      border: `1px solid grey`
    }
  },
}));
