export const emptyUserObject = {
  id: '',
  name: '',
  email: '',
  token: '',
  sessionId: null,
  error: false,
  errorMsg: ''
}

export const setUser = (user) => {
  if(typeof user === 'object'){
    localStorage.setItem('pandai_widget', JSON.stringify(user));
  }
}
export const getUser = () => {
  const userJSON = localStorage.getItem('pandai_widget');
  let user = emptyUserObject;
  try {
    user = JSON.parse(userJSON);
  }catch(e){
    console.log("Error: Cound not decode data from localstorage");
  }

  return typeof user === 'object' && user !== null ? user : emptyUserObject;
}

export const removeUser = () => {
  localStorage.removeItem('pandai_widget');
}
