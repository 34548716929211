import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  messageContainer: {
    padding: '16px 18px',
    fontSize: 14,
    textAlign: 'left',
    borderRadius: 10,
    margin: '10px 0px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    lineHeight: '1.2',
    maxWidth: 280,
    display: 'block',
    position: 'relative',
    marginLeft: 40,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 25,
    float: 'right',
    color: ({USER_MESSAGE_COLOR}) => USER_MESSAGE_COLOR,
    backgroundColor: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    '& img': {
      padding: 10,
      width: 40,
      height: 45,
      paddingLeft: 0
    }
  },
  userTimeStamp: {
    fontSize: 9,
  },
  internalText: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  },
  clearDiv: {
    clear: 'both'
  }
}));
