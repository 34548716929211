import { removeUser } from '../_helpers/localStorage'
import InstanceWrapper from '../_services/api'
// import axios from 'axios'

export function startSession(id){
  return dispatch => dispatch({type: 'SESSION_CONNECT_SUCCESS', payload: { sessionId: id}})
}

export function endSession(){
  return dispatch => dispatch({type: 'SESSION_END'})
}

export function login({ email, password }){
  return function (dispatch){
      InstanceWrapper('LOGIN_POST','/user/login',{
        email,
        password
      })
      .then(res=>{
        dispatch({type: 'LOGIN_SUCCESS', payload: res.data})
      })
      .catch(err=>{
        console.log(err);
        dispatch({ type: 'LOGIN_FAILED', payload: { error: 'FAILED TO LOGIN' }})
      })
  }
}

export function logout(){
  return function (dispatch){
    removeUser()
    dispatch({ type: 'LOGOUT_SUCCESS'})
    // axios.get("/authentication/logout")
    //   .then(res=>{
    //     dispatch({type: res.data.status, payload: res.data.userData})
    //     // console.log("logout response from server",res.data);
    //   })
  }
}

export function toggleNightMode(){
  return dispatch => dispatch({ type: 'TOGGLE_NIGHT_MODE'})
}
