import React from 'react';
// import clsx from 'clsx'
import useStyles from '../styles/referralMessageStyles'

export default function ReferralMessage({ data, config, ...props }) {
  const { text } = data
  const classes = useStyles({...config, text});


  return (
    <div className={classes.root}>
      <hr className={classes.lineWithText} data-testid='referral-text' />
    </div>
  );
}
