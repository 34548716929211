//import icons
import png from '../assets/icons/fileTypes/png.png'
import jpg from '../assets/icons/fileTypes/jpg.png'
import doc from '../assets/icons/fileTypes/doc.png'
import pdf from '../assets/icons/fileTypes/pdf.png'
import ppt from '../assets/icons/fileTypes/ppt.png'
import raw from '../assets/icons/fileTypes/raw.png'

export default type => {
   switch(type){
      case 'png':
      return png
      case 'jpg':
      case 'jpeg':
      return jpg
      case 'doc':
      case 'docx':
      return doc
      case 'pdf':
      return pdf
      case 'ppt':
      case 'pptx':
      return ppt
      default:
      return raw
    }
}
